import React from 'react';
import Helmet from 'react-helmet';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import PageLoader from '@quantore/gatsby-theme-quantore/src/components/Loader/PageLoader';
import Breadcrumb from '@quantore/gatsby-theme-quantore/src/components/Breadcrumb/Breadcrumb';

export const query = graphql`
  query PageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...PageFragment
    }
  }
`;

const Hero = loadable(
  () => import('@quantore/gatsby-theme-quantore/src/components/Hero/Hero'),
  {
    fallback: <PageLoader />,
    cacheKey: () => 'Hero',
  }
);

const ComponentList = loadable(
  () =>
    import(
      '@quantore/gatsby-theme-quantore/src/components/ComponentList/ComponentList'
    ),
  {
    fallback: <PageLoader />,
    cacheKey: () => 'ComponentList',
  }
);

const Page = ({ pageContext, location, data }) => {
  const { contentfulPage } = data;
  const {
    components,
    hero,
    title,
    breadcrumbs,
    metaTagTitle,
    metaTagDescription,
    metaTagRobots,
  } = contentfulPage;

  return (
    <>
      <Helmet>
        <title>{metaTagTitle ? metaTagTitle : title}</title>
        <link rel="canonical" href={location.href} />
        <meta name="title" content={metaTagTitle ? metaTagTitle : title} />
        <meta
          name="robots"
          content={metaTagRobots ? metaTagRobots : 'index, follow'}
        />
        {metaTagDescription && (
          <meta name="description" content={metaTagDescription} />
        )}

        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={metaTagTitle ? metaTagTitle : title}
        />
        {metaTagDescription && (
          <meta property="og:description" content={metaTagDescription} />
        )}
        {hero && hero.image && hero.image.file.url && (
          <meta property="og:image" content={hero.image.file.url} />
        )}

        <meta property="twitter:url" content={location.href} />
        <meta
          property="twitter:title"
          content={metaTagTitle ? metaTagTitle : title}
        />
        <meta property="twitter:card" content="summary_large_image" />
        {metaTagDescription && (
          <meta property="twitter:description" content={metaTagDescription} />
        )}
        {hero && hero.image && hero.image.file.url && (
          <meta property="twitter:image" content={hero.image.file.url} />
        )}
      </Helmet>

      {breadcrumbs !== false && (
        <Breadcrumb breadcrumb={pageContext.breadcrumb} />
      )}

      {hero && <Hero {...hero} />}
      <ComponentList id="content" components={components} />
    </>
  );
};

export default Page;
